import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    Button,
    Select,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification
} from 'antd';

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'
import { useTranslation, Trans } from "react-i18next";

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

export function Login({ kernel }) {
    let params = useParams();

    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()
    const { t, i18n, ready } = useTranslation();
    
    // Special implementation for jumpToken in order to force
    // UI to the set the internal token and move to /
    // used by SSO
    useEffect(() => {
        const rebound = searchParams.get("jumpToken")
        if (rebound) {
            setLoading(true)

            const key = rebound.split(">")
            key[0] = Buffer.from(key[0], 'hex').toString('base64')
            key[1] = Buffer.from(key[1], 'hex').toString('base64')
            key[2] = Buffer.from(key[2], 'hex').toString('base64')
            const token = key.join(">")

            kernel.session.store(token) // ca n'existe plus 

            setTimeout(() => {
                setLoading(false)
                navigate("/")
            }, 1000)
        }
    }, [loaded])

    const onFinish = async (values) => {
        setLoading(true)

        const ret = await kernel.session.login(values.email, values.password)
        if (ret) {
            notification.error({
                message: ret
            })
            setLoading(false)
            return
        }
        notification.success({
            message: t('Login.loggedInMessage')
        })
        setLoading(false)
        navigate("/dohzel/profile")
    }

    const onFinishFailed = async (errorInfo) => {
        // console.log('Failed:', errorInfo);
    }

    if (ready) {
        return (
            <DiventryLayoutSimple>

                <Spin tip={t('Loading')} spinning={loading}>
                    <Form
                        {...layout}
                        name="basic"
                        style={{
                            // maxWidth: 600,
                        }}
                        initialValues={{
                            // remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t('Login.email')}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t('Login.emailRequired'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t('Login.password')}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t('Login.passwordRequired'),
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Col span={16} offset={8}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    {t('Login.signIn')}
                                </Button>
                                <span style={{ fontSize: "0.9em" }}>
                                    <Link to="/session/forgot">{t('Login.forgotPassword')}</Link>
                                </span>
                            </Space>
                        </Col>

                        <Col span={16} offset={5} style={{ paddingTop: 20, fontSize: "0.9em" }}>
                            <Link to="/session/register">{t('Login.noAccount')}</Link>
                        </Col>
                    </Form>
                </Spin>

            </DiventryLayoutSimple>
        );
    }
    return <span>{t('Loading')}</span>;
}
