import React from 'react';
import { Space, Tag } from 'antd';
import { useTranslation } from "react-i18next";

import appleiOS from './appleiOS.png'
import appleMac from './appleMac.png'
import googleAndroid from './googleAndroid.png'
import windows from './windows.png'
import linux from './linux.png'
import freeBSD from './freeBSD.png'
import fritz from './fritz.svg'
import opnSense from './openSense.png'
import dohzel from './dohzel.png'
import genericFirewall from './genericFirewall.png'
import browser from './browser.png'
import fortinet from './fortinet.png'
import mikrotik from './mikrotik.svg'

const Brands = {
    appleiOS,
    appleMac,
    googleAndroid,
    windows,
    linux,
    freeBSD,
    fritz,
    opnSense,
    dohzel,
    proxy: dohzel,
    genericFirewall,
    browser,
    fortinet,
    mikrotik
}

const DeviceTypes = {
    appleMac: "Apple MacOS",
    appleiOS: "Apple iOS",
    googleAndroid: "Google Android",
    windows: "Windows",
    linux: "Linux",
    freeBSD: "FreeBSD",
    proxy: "DoHzel Proxy",
    opnSense: "OPNsense",
    fritz: "FritZ! Box",
    fortinet: "Fortinet",
    browser: "Browser",
    genericFirewall: "Generic Firewall",
    mikrotik: "Mikrotik RouterOS"
}

function BrandedDevice({ device, size = 40, font = 14, connectStatus = false }) {
    const { t, ready } = useTranslation(); // Utilisation de useTranslation

    if (!ready) {
        return <span>{t('BrandedDevice.Loading')}</span>; // Affiche le texte de chargement
    }

    const small = Math.ceil(font * 0.5) < 11 ? 11 : Math.ceil(font * 0.4)

    return (
        <Space>
            <div style={{
                padding: 6,
                backgroundColor: "white",
                borderRadius: 10
            }}>
                <img src={Brands[device.deviceType]} width={size} />
            </div>

            <div>
                <div style={{ fontWeight: 700, fontSize: font }}>
                    {device.name || t('BrandedDevice.NoName')}
                </div>
                <small style={{ fontSize: small }}>
                    {DeviceTypes[device.deviceType]} {device?.device?.browser?.os?.version ? `v${device.device.browser.os.version.trim()} ` : null}
                    {device?.device?.browser?.device?.model ? `on ${device.device.browser.device.model}` : null}

                    {device.deviceType === "proxy" && device.hasOwnProperty("connectCap") ? <>
                        {connectStatus === false ?
                            <Tag
                                color="green"
                                bordered={false}
                                style={{
                                    fontWeight: 700
                                }}>
                                {t('BrandedDevice.ConnectCapable')}
                            </Tag> : null}

                        {device.connectCap === true ?
                            <Tag color="green" bordered={false}>{t('BrandedDevice.Connected')}</Tag> :
                            <Tag color="orange" bordered={false}>{t('BrandedDevice.Disconnected')}</Tag>
                        }
                    </> : null}
                </small>
            </div>
        </Space>
    )
}

function BrandedDeviceLine({ type }) {
    const { t, ready } = useTranslation(); // Utilisation de useTranslation

    if (!ready) {
        return <span>{t('BrandedDevice.Loading')}</span>; // Affiche le texte de chargement
    }

    return (
        <Space>
            <img src={Brands[type]} width={20} />
            <div>
                {DeviceTypes[type]} {/* Traduction du type de device */}
            </div>
        </Space>
    )
}

export default {
    ...Brands,
    DeviceTypes,
    BrandedDevice,
    BrandedDeviceLine,
}
