import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Button,
    Modal,
    Space,
    Spin,
    Table,
    Form,
    Input,
    Select,
    notification,
    theme,
    Radio,
    Popconfirm
} from 'antd';

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

const { useToken } = theme;

export function Offer({ kernel }) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const [loaded, setLoaded] = useState(true);
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Offer Name',
            dataIndex: 'value',
            key: 'name',
        },
        {
            title: 'Device Type',
            dataIndex: 'key',
            key: 'deviceType',
            render: (text) => <strong>{text}</strong>
        },
        {
            title: 'Dohzel Offer',
            dataIndex: 'options',
            key: 'options',
            render: (options, record) => <div>
                <Radio.Group
                    block
                    options={options?.map(option => ({
                        ...option,
                        label: option.label?.toLowerCase() == 'mobile' ? 'Enpoint' : option.label
                    }))}
                    defaultValue={record?.offer}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(option) => createOffer(option?.target?.value, record?.key, record?.id)}
                />
            </div>
        },
    ];

    // async load
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const response = await kernel.api.get(`/dohzel/offer/devices`);
    
                if (response.error) {
                    notification.error({
                        message: `Error getting offers list`,
                        description: response.error
                    });
                    setLoading(false);
                    return;
                }

                if (Array.isArray(response.data)) {
                    setOffers(response.data);
                } else {
                    notification.warning({
                        message: 'No offers found',
                        description: 'The offers data is not available or is not in the expected format.'
                    });
                }
            } catch (error) {
                notification.error({
                    message: `An error occurred while fetching offers`,
                    description: error.message || 'Unknown error'
                });
            } finally {
                setLoading(false);
            }
        }
    
        fetch();
    }, [loaded]);  
    
    const createOffer = async (offer, deviceType, offerId) => {
        const data = {
            offer,
            deviceType,
            id: offerId
        };
        const ret = await kernel.api.post(`/dohzel/offer/${offerId ? `update` : `create`}`, data)
        if (!ret || ret.error) {
            notification.error({
                message: `Error while ${offerId ? `updating` : `creating`} offer`,
                description: ret.error
            })
            return
        }
        else {
            notification.success({
                message: `Offer successfully ${offerId ? `updated` : `created`}`
            })
        }

        setLoaded(!loaded);

    }
    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="Offers Management"
                description="Manage your offers for different device types"
            />

            <DiventryLayoutContent>
                <DiventryBlock>
                    <Table
                        loading={loading}
                        showSizeChanger={true}
                        dataSource={offers}
                        columns={columns}
                        pagination={{ defaultPageSize: 50, showSizeChanger: true, pageSizeOptions: ['50', '100', '200'] }}
                    />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>
    );
}
