import { useState } from "react"
import { useNavigate } from "react-router-dom"

import {
    Button,
    Form,
    Input,
    Col,
    Space,
    Spin,
    notification
} from 'antd'

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'
import { useTranslation } from "react-i18next";

const item = {
    wrapperCol: { span: 16, offset: 8 },
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

export function Forgot({ kernel }) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { t, i18n, ready } = useTranslation();

    const onFinish = async (values) => {
        setLoading(true)
        const response = await kernel.api.post('/user/forget', values)
        if (response.error) {
            notification.error({
                description: <>{response.error}</>
            })
            setLoading(false)
            return
        }
        notification.success({
            message: response.data
        })
        setLoading(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    if (ready) {
        return (
            <DiventryLayoutSimple>
                <Spin tip={t('Loading')} spinning={loading}>
                    <Form
                        name="basic"
                        {...layout}
                        style={{
                            // maxWidth: 600,
                        }}
                        initialValues={{
                            // remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t('Forgot.email')}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t('Forgot.emailRequired'),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Col span={16} offset={8}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    {t('Forgot.resetPassword')}
                                </Button>
                                <Button onClick={() => navigate("/session/login")}>
                                    {t('Forgot.back')}
                                </Button>
                            </Space>
                        </Col>
                    </Form>
                </Spin>
            </DiventryLayoutSimple>
        )
    }
    return <span>{t('Loading')}</span>;
}
