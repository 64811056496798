import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Button,
    Space,
    notification
} from 'antd';

import { DiventryLayoutSimple } from '../../Templates/LayoutSimple'
import { useTranslation } from "react-i18next";

export function Validate({ kernel }) {
    let params = useParams();
    const { t, ready } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const onValidate = async (values) => {
        setLoading(true);
        const response = await kernel.api.post('/user/validate', { token: params.token });
        if (response.error) {
            notification.error({
                description: <>{response.error}</>
            });
            setLoading(false);
            return;
        }
        notification.success({
            message: t('Validate.redirectMessage')
        });
        setTimeout(() => navigate("/"), 1000);
    };

    if (ready) {
        return (
            <DiventryLayoutSimple>
                <Space>
                    <Button size="large" type="primary" onClick={onValidate} loading={loading}>
                        {t('Validate.validateButton')}
                    </Button>
                </Space>
                <p style={{ fontSize: "0.9em", paddingTop: "25px" }}>
                    {t('Validate.validationText')}
                </p>
                {process.env.REACT_APP_ENV === "development" ? 
                    <p style={{ fontSize: "0.8em" }}>
                        {params.token}
                    </p>
                : null}
            </DiventryLayoutSimple>
        );
    }

    return <span>{t('Loading')}</span>;
}
