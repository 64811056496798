import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Col,
    notification,
    Spin,
    theme,
} from 'antd';
import { DiventryLayoutSimple } from '../../Templates/LayoutSimple';
import { useTranslation } from "react-i18next";

const item = {
    wrapperCol: { span: 16, offset: 8 },
};

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

export function ChangePassword({ kernel }) {
    let params = useParams();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { t, ready } = useTranslation();

    const onFinish = async (values) => {
        setLoading(true);
        const response = await kernel.api.post('/user/password/token', { ...values, token: params.token });

        if (response.error) {
            notification.error({
                message: <>{response.error}</>
            });
            setLoading(false);
            return;
        }
        notification.success({
            message: t('ChangePassword.passwordChanged')
        });

        kernel.session.store(response.data.token);
        setLoading(false);
        navigate("/");
    };

    const onFinishFailed = async (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    if (ready) {
        return (
            <DiventryLayoutSimple>
                <Spin tip={t('Loading')} spinning={loading}>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={t('ChangePassword.password')}
                            name="password1"
                            rules={[{
                                required: true,
                                message: t('ChangePassword.passwordRequired'),
                            }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label={t('ChangePassword.verification')}
                            name="password2"
                            rules={[{
                                required: true,
                                message: t('ChangePassword.passwordRequired'),
                            }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...item}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {t('ChangePassword.changePassword')}
                            </Button>
                        </Form.Item>

                        <Col span={16} offset={8} style={{ fontSize: "0.9em" }}>
                            <Link to="/session/login">{t('ChangePassword.backToLogin')}</Link>
                        </Col>
                    </Form>
                </Spin>
            </DiventryLayoutSimple>
        );
    }

    return <span>{t('Loading')}</span>;
}
