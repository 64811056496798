
import { useRef, useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    notification,
    Switch,
    InputNumber
} from 'antd'

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'
import { DomainsStatistics } from './Domains';


import Statistic from './../../../Templates/Statistic';

import { ThreatDBSearch } from '../../../Templates/ThreatDBSearch'

const { Search } = Input;

const columns = [
    {
        title: 'Index',
        dataIndex: 'index',
        key: 'index'
    },
    // {
    //     title: 'Name',
    //     dataIndex: 'name',
    //     key: 'name',
    // },
    // {
    //     title: 'Tags',
    //     dataIndex: 'tags',
    //     key: 'tags',
    // },
    {
        title: 'Domains',
        dataIndex: 'domains',
        key: 'domains',
        sorter: (a, b) => a.stats.domains.lines - b.stats.domains.lines,

    },
    {
        title: 'IPv4',
        dataIndex: 'ipv4',
        key: 'ipv4',
        sorter: (a, b) => a.stats.ipv4.lines - b.stats.ipv4.lines,
    },
    {
        title: 'IPv6',
        dataIndex: 'ipv6',
        key: 'ipv6',
        sorter: (a, b) => a.stats.ipv6.lines - b.stats.ipv6.lines,
    },
    // {
    //     title: '',
    //     dataIndex: 'buttons',
    //     key: 'buttons',
    //     align: "right"
    // },
]

export function List({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [table, setTable] = useState([])
    const [globalStats, setGlobalStats] = useState([])

    // for list creation
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [createForm] = Form.useForm()

    const [filteredTable, setFilteredTable] = useState([])
    const [user, setUser] = useState({});
    const [listSearch, setListSearch] = useState("")
    const searchInput = useRef()

    const navigate = useNavigate()

    async function fetch() {
        const newTable = []
        const raw = await kernel.api.get('/threatdb/list/list')
        if (!raw || raw.error) {
            notification.error({
                message: 'Error fetching list',
                description: raw.error
            })
            return
        }

        for (let data of raw.data) {
            data.key = data._id

            // process tags
            data.tags = []
            if (data.public === true)
                data.tags.push(<Tag color="#5d782e">Public</Tag>)
            else
                data.tags.push(<Tag color="#9e482f">Private</Tag>)

            if (data.allowlistAllowed === true)
                data.tags.push(<Tag>Allowlist</Tag>)
            if (data.mergeMaster === true)
                data.tags.push(<Tag>Merge master</Tag>)

            if (data.retention > 0)
                data.tags.push(<Tag>Retention = {data.retention}</Tag>)
            else
                data.tags.push(<Tag>Permanent</Tag>)

            // index
            data.index = <div >
                <Link style={{ fontSize: 26, fontWeight: 800, color: "black" }} to={'/threatdb/list/detail/' + data._id}>{data.name}</Link>
                <div>
                    {data.tags}
                </div>
            </div>

            data.domains = <div>
                <div style={{ fontSize: 27, fontWeight: 700 }} >{data.stats.domains.lines}</div>
                <div style={{ fontSize: 10, fontWeight: 500 }} >{new Date(data.stats.domains.update).toLocaleString()}</div>
            </div>

            data.ipv4 = <div>
                <div style={{ fontSize: 27, fontWeight: 700 }} >{data.stats.ipv4.lines}</div>
                <div style={{ fontSize: 10, fontWeight: 500 }} >{new Date(data.stats.ipv4.update).toLocaleString()}</div>
            </div>

            data.ipv6 = <div>
                <div style={{ fontSize: 27, fontWeight: 700 }} >{data.stats.ipv6.lines}</div>
                <div style={{ fontSize: 10, fontWeight: 500 }} >{new Date(data.stats.ipv6.update).toLocaleString()}</div>
            </div>

            // process button
            data.buttons = <Space.Compact size="small">
                <Button onClick={() => {
                    navigate('/threatdb/list/detail/' + data._id)
                }}>Detail</Button>
            </Space.Compact>
            newTable.push(data)
        }
        setTable(newTable)
        setFilteredTable(newTable)
    }

    useEffect(() => {
        async function fetch() {
            var cUser = await kernel.api.get("/user/me")
            if (cUser.error)
                return
            setUser(cUser.data)
        }
        fetch()
    }, [])

    async function fetchGlobalStats() {
        const raw = await kernel.api.get('/threatdb/list/build/global')
        if (!raw || raw.error) {
            notification.error({
                message: 'Error fetching list',
                description: raw.error
            })
            return
        }

        const data = [
            {
                name: 'Total',
                value: (raw?.data?.domain?.stats?.lines ?? 0) +
                    (raw?.data?.ipv4?.stats?.lines ?? 0) + (raw?.data?.ipv6?.stats?.lines ?? 0),
            },
            {
                name: 'Domain',
                value: raw?.data?.domain?.stats?.lines ?? 0,
            },
            {
                name: 'IPv4',
                value: raw?.data?.ipv4?.stats?.lines ?? 0,
            },
            {
                name: 'IPv6',
                value: raw?.data?.ipv6?.stats?.lines ?? 0,
            }
        ];

        setGlobalStats(data);
    }

    useEffect(() => {
        fetch()
        fetchGlobalStats()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue()
        }
        const ret = await kernel.api.post(`/threatdb/list/add`, data)
        if (ret.error) {
            notification.error({
                message: 'Error updating information',
                description: ret.fields ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : ret.error
            })
            return
        }
        notification.success({
            message: 'New list created'
        })
        setIsModalCreate(false)
        navigate(`/threatdb/list/detail/${ret.data.id}`)
    }

    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    const handleSearch = () => {
        const newTable = []
        for (var item of table) {

            if (listSearch.length === 0)
                newTable.push(item)
            else if (item.name.toLowerCase().indexOf(listSearch.toLowerCase()) >= 0)
                newTable.push(item)
        }
        setFilteredTable(newTable)
    }





    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="List management"
                description="Threats database incident & profiling lists"
            />
            <DiventryLayoutContent>

                <Modal title="Creating a list" open={isModalCreate} onOk={handleCreateOk} onCancel={handleCreateCancel}>
                    <Form
                        layout="vertical"
                        form={createForm}
                    >
                        <Form.Item label="Index name" name="index">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Name" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="List visibility" name="public">
                            <Switch checkedChildren="Public" unCheckedChildren="Private" />
                        </Form.Item>

                        <Form.Item label="Allowlist permitted" name="allowlistAllowed">
                            <Switch checkedChildren="Allowed" unCheckedChildren="Disallowed" />
                        </Form.Item>

                        <Form.Item label="Merge into master" name="mergeMaster">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>

                        <Form.Item label="Data retention" name="retention">
                            <InputNumber addonAfter="seconds" />
                        </Form.Item>

                        <Form.Item label="Website URL" name="website">
                            <Input />
                        </Form.Item>

                    </Form>
                </Modal>

                <DiventryBlock>
                    <Space>

                        {
                            user.admin && 
                            <Button onClick={() => {
                                createForm.resetFields()
                                setIsModalCreate(true)
                            }}>
                                Create new list
                            </Button>
                        }

                        <Space.Compact>
                            <Button type="primary" onClick={() => {
                                navigate("/threatdb/global/domains/data")
                            }}>
                                Master Domains
                            </Button>
                            <Button type="primary" onClick={() => {
                                navigate("/threatdb/global/ipv4/data")
                            }}>
                                Master IPv4
                            </Button>
                            <Button type="primary" onClick={() => {
                                navigate("/threatdb/global/ipv6/data")
                            }}>
                                Master IPv6
                            </Button>

                        </Space.Compact>

                        <ThreatDBSearch kernel={kernel} />

                    </Space>

                </DiventryBlock>

                <Statistic
                    kernel={kernel}
                    endpoint={`/threatdb/list/stats/global/last90d`}
                    type='line'
                    title='Indicator Of Compromise into the global list'
                    globalStats={globalStats}
                    statTypes={{
                        Added: '#3498db',
                        Updated: '#e67e22',
                        Removed: '#c0392b'
                    }}
                />
                <DiventryBlock>
                    <p>
                        <Search
                            addonBefore="Searching list"
                            placeholder="input search text"
                            onChange={(e) => setListSearch(e.target.value)}
                            onSearch={handleSearch}
                            style={{
                                width: 500,
                            }}
                        />
                    </p>
                    <Table dataSource={filteredTable} columns={columns} pagination={false} bordered={true} />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>

    );
}