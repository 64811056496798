import { useState, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Col,
    Space,
    Spin,
    theme,
    notification,
} from 'antd';
import { UtilsInputLang } from "../../Templates/Utils";
import { DiventryLayoutSimple } from '../../Templates/LayoutSimple';
import { useTranslation } from "react-i18next";

const item = {
    wrapperCol: { span: 16, offset: 8 },
};

const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
};

export function Register({ kernel }) {
    let params = useParams();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({});
    const [help, setHelp] = useState({});
    const [confirm, setConfirm] = useState(false);
    const { t, ready } = useTranslation();

    const onFinish = async (values) => {
        setLoading(true);
        const response = await kernel.api.post('/user/register', values);
        if (response.error) {
            notification.error({
                message: t('Register.signInError'),
                description: <>{response.error}</>,
            });

            if (response.fields) {
                const newStatus = {};
                const newHelp = {};
                for (var field in values) {
                    newStatus[field] = "success";
                    newHelp[field] = null;
                }
                for (var field of response.fields) {
                    newStatus[field[0]] = "error";
                    newHelp[field[0]] = field[1];
                }

                setStatus(newStatus);
                setHelp(newHelp);
            }
            setLoading(false);
            return;
        }
        notification.success({
            message: t('Register.thankYou'),
        });
        setLoading(false);
        setConfirm(true);
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    if (ready) {
        return (
            <DiventryLayoutSimple>
                {confirm === true ? (
                    <div style={{ fontSize: "1.4em", textAlign: "center" }}>
                        <h1>{t('Register.thankYouHeading')}</h1>
                        <p style={{ width: "100%" }}>
                            {t('Register.confirmationEmail')}
                        </p>
                    </div>
                ) : null}

                {confirm === false ? (
                    <Spin tip={t('Loading')} spinning={loading}>
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                lang: navigator.language.split("-")[0],
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                validateStatus={status.firstName}
                                help={help.firstName}
                                label={t('Register.firstName')}
                                name="firstName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                validateStatus={status.lastName}
                                help={help.lastName}
                                label={t('Register.lastName')}
                                name="lastName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                validateStatus={status.lang}
                                help={help.lang}
                                label={t('Register.language')}
                                name="lang"
                            >
                                <UtilsInputLang />
                            </Form.Item>

                            <Form.Item
                                label={t('Register.email')}
                                name="email"
                                validateStatus={status.email}
                                help={help.email}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Register.emailRequired'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={t('Register.password')}
                                name="password1"
                                validateStatus={status.password1}
                                help={help.password1}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Register.passwordRequired'),
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="new-password" />
                            </Form.Item>

                            <Form.Item
                                label={t('Register.passwordCheck')}
                                name="password2"
                                validateStatus={status.password2}
                                help={help.password2}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Register.passwordCheckRequired'),
                                    },
                                ]}
                            >
                                <Input.Password autoComplete="new-password" />
                            </Form.Item>

                            <Col span={16} offset={8}>
                                <Space>
                                    <Button type="primary" htmlType="submit">
                                        {t('Register.registerButton')}
                                    </Button>
                                    <span style={{ fontSize: "0.9em" }}>
                                        <Link to="/session/login">
                                            {t('Register.loginPageLink')}
                                        </Link>
                                    </span>
                                </Space>
                            </Col>
                        </Form>
                    </Spin>
                ) : null}
            </DiventryLayoutSimple>
        );
    }

    return <span>{t('Loading')}</span>;
}
